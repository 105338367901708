import React from 'react'
import { useRecoilState } from 'recoil'
import { InlineLoader } from '../components/loader/Loader'
import { TabsComponent } from '../components/tabs/Tabs'
import { sentimentState, transcriptionState } from '../recoil'

export const Home = () => {
  const [transcription, setTranscription] = useRecoilState(transcriptionState)
  const [sentiment, setSentiment] = useRecoilState(sentimentState)

  const reset = () => {
    setTranscription(null)
    setSentiment(null)
  }

  return !transcription ? (
    <section className="question">
      <h5>
        Seleziona o registra il file audio da trascrivere (formati accettati:
        .mp3), oppure inserisci un testo da analizzare
      </h5>
      <p className="descr">
        Per il file/testo che verrà analizzato riceverai la trascrizione e
        un'analisi del sentiment (una valutazione sintetica in forma numerica --
        es: 1 molto soddisfatto...10: molto arrabbiato -- e una descrizione
        parlante contenente le motivazioni).
      </p>
      <TabsComponent />
    </section>
  ) : (
    <section id="result-container">
      <button className="home-button" disabled={!sentiment} onClick={reset}>
        Esegui una nuova analisi
      </button>
      <div id="line" />
      <div id="results">
        <div id="result-box">
          <span>Trascrizione: </span>
          <p className="analysis-descr">
            Qui trovi la trascrizione del file audio che hai analizzato
          </p>
          <p className="analysis-value">{transcription.transcription}</p>
        </div>
        <div id="result-box">
          <span>Analisi del sentimento: </span>
          <p className="analysis-descr">
            Qui trovi l'analisi del sentimento del file audio che hai
            analizzato: l'analisi è composta da una valutazione sintetica in
            forma numerica -- es: 1 molto soddisfatto...10: molto arrabbiato --
            e una descrizione parlante contenente le motivazioni
          </p>
          {sentiment ? (
            <p className="analysis-value">{sentiment?.sentiment}</p>
          ) : (
            <div className="analysis-value-placeholder">
              <InlineLoader />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
