import React from 'react'
import { useRecord } from '../../hooks/useRecord'
import './Record.css'
import { useAnalyseSentiment } from '../../hooks/useAnalyseSentiment'

export const Record = () => {
  const [rec, setRec] = React.useState(null)
  const { analyseFromAudio } = useAnalyseSentiment()
  useRecord(setRec)

  return (
    <div className="wrapper">
      <div className="main-controls">
        <div id="buttons">
          <button className="record home-button">Registra il tuo audio</button>
          <button className="stop home-button">Interrompi registrazione</button>
        </div>
        <canvas className="visualizer" height="60px"></canvas>
      </div>

      <div className="sound-clips"></div>
      {rec && (
        <button
          className="home-button select black-button"
          onClick={() => analyseFromAudio(rec)}
        >
          Analizza file audio
        </button>
      )}
    </div>
  )
}
