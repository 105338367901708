import { useSetRecoilState } from 'recoil'
import { useApi } from './useApi'
import {
  errorState,
  loaderState,
  sentimentState,
  transcriptionState,
} from '../recoil'
import { EChannels, ITranscribeAudioFileResponse } from '../utils/types'

export const useAnalyseSentiment = () => {
  const setError = useSetRecoilState(errorState)
  const setLoader = useSetRecoilState(loaderState)
  const setTranscription = useSetRecoilState(transcriptionState)
  const setSentiment = useSetRecoilState(sentimentState)
  const { transcribeAudioFile, analyzeSentiment } = useApi()

  const analyseFromAudio = async (file: File, channel?: EChannels) => {
    setError(null)
    setLoader(true)
    const formData = new FormData()
    formData.append('uploadFile', file)
    const transcription = await transcribeAudioFile(
      formData,
      channel || EChannels.Both,
    )
    setLoader(false)
    if (transcription.error) {
      setError(transcription)
    } else {
      setTranscription(transcription as ITranscribeAudioFileResponse)
      const sentiment = await analyzeSentiment(transcription, {
        noLoader: true,
      })
      setSentiment(sentiment)
    }
  }

  const analyseFromText = async (transcription: string) => {
    setError(null)
    setLoader(true)
    const sentiment = await analyzeSentiment({
      transcription,
      checkLanguage: true,
    })
    setLoader(false)
    if (sentiment.error) {
      setError(sentiment)
    } else {
      setTranscription({ transcription })
      setSentiment(sentiment)
    }
  }

  return {
    analyseFromAudio,
    analyseFromText,
  }
}
