import React from 'react'
import toast from 'react-hot-toast'
import { useSetRecoilState } from 'recoil'
import { useApi } from '../../hooks/useApi'
import { authState } from '../../recoil'
import './Login.css'

async function crypt(message) {
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  return hashHex
}

export const Login = () => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const setAuth = useSetRecoilState(authState)

  const { login } = useApi()

  const login_ = async (e) => {
    e.preventDefault()
    const hash: string = await crypt(password)
    login({ username, password: hash })
      .then((res) => {
        toast.success('Benvenuto!')
        localStorage.setItem('access_token', res.access_token)
        localStorage.setItem('refresh_token', res.refresh_token)
        setAuth(res)
      })
      .catch((err) => toast.error(err.message))
  }

  return (
    <div id="login">
      <h1>Accedi</h1>
      <form onSubmit={login_}>
        <input
          type="text"
          value={username}
          placeholder="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          value={password}
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  )
}
