import React from 'react'
import './uploadFile.css'
import { useRecoilState } from 'recoil'
import { errorState } from '../../recoil'
import { useAnalyseSentiment } from '../../hooks/useAnalyseSentiment'
import Select from 'react-select'
import { EChannels } from '../../utils/types'

const options = [
  { value: EChannels.Right, label: 'Operatore' },
  { value: EChannels.Left, label: 'Cliente' },
  { value: EChannels.Both, label: 'Tutti' },
]

export const UploadFile = () => {
  const [error, setError] = useRecoilState(errorState)
  const [selectedFile, setSelectedFile] = React.useState<File>(null)
  const [selectedChannel, setSelectedChannel] = React.useState<any>(options[0])
  const inputRef = React.useRef(null)
  const { analyseFromAudio } = useAnalyseSentiment()

  React.useEffect(() => {
    if (selectedFile && !selectedFile.type.includes('audio/mpeg')) {
      inputRef.current.value = null
      setSelectedFile(null)
      setError({
        error: null,
        statusCode: 400,
        message: 'Il formato del file non è supportato. Carica un file mp3.',
      })
    }
  }, [selectedFile])

  return (
    <>
      <p className="content-description">
        Seleziona un file e indica quale canale vuoi analizzare (seleziona {''}
        <strong>Operatore</strong> o <strong>Cliente</strong> per isolare una
        delle due parti, oppure seleziona <strong>Tutti</strong> per analizzare
        l'audio interamente senza distinzioni)
      </p>
      <div className="row">
        <label className="custom-file-upload">
          <input
            type="file"
            ref={(ref) => (inputRef.current = ref)}
            className="select-file"
            accept=".mp3"
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          {selectedFile ? (
            <>
              <strong>{selectedFile.name}</strong>
              seleziona un file audio
            </>
          ) : (
            'Seleziona un file audio'
          )}
        </label>
        <Select
          options={options}
          onChange={(newValue) => setSelectedChannel(newValue)}
          value={selectedChannel}
          isSearchable={false}
          classNames={{
            control: () => 'select-container',
          }}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: '#163d65',
              borderRadius: 5,
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#83c1ff',
              primary: '#163d65',
            },
          })}
        />
      </div>
      <button
        className="home-button black-button"
        onClick={() => analyseFromAudio(selectedFile, selectedChannel.value)}
        disabled={!selectedFile || Boolean(error)}
      >
        Analizza file audio
      </button>
    </>
  )
}
