import React from 'react'
import { useRecoilState } from 'recoil'
import { authState, pageState } from '../recoil'
import { EPages } from '../utils/types'
import { useApi } from './useApi'

export const useAuth = () => {
  const [auth, setAuth] = useRecoilState(authState)
  const [page, setPage] = useRecoilState(pageState)
  const { checkTokenValidity } = useApi()

  React.useEffect(() => {
    const localAccessToken = localStorage.getItem('access_token')
    const localRefreshToken = localStorage.getItem('refresh_token')

    if (localAccessToken) {
      checkTokenValidity({ refresh_token: localRefreshToken })
        .then(() => {
          setAuth({
            access_token: localAccessToken,
            refresh_token: localRefreshToken,
          })
        })
        .catch(() => {
          setAuth({
            access_token: null,
            refresh_token: null,
          })
        })
    } else {
      setAuth({
        access_token: null,
        refresh_token: null,
      })
    }
  }, [])

  React.useEffect(() => {
    if (auth?.access_token && (!page || page === EPages.Login)) {
      setPage(EPages.Home)
    } else {
      setPage(EPages.Login)
    }
  }, [auth])
}

export const useLogout = () => {
  const [, setAuth] = useRecoilState(authState)

  const logout = () => {
    setAuth({ access_token: null, refresh_token: null })
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
  }

  return logout
}
