/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React from 'react'

export const useRecord = (setRec) => {
  const animationFrameID = React.useRef(null)

  React.useEffect(() => {
    const record = document.querySelector('.record')
    const stop = document.querySelector('.stop')
    const soundClips = document.querySelector('.sound-clips')
    const canvas = document.querySelector('.visualizer')
    const mainSection = document.querySelector('.main-controls')

    // disable stop button while not recording

    stop.disabled = true

    // visualiser setup - create web audio api context and canvas

    let audioCtx
    const canvasCtx = canvas.getContext('2d')

    //main block for doing the audio recording

    if (navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.')

      const constraints = { audio: true }
      let chunks = []

      const onSuccess = function (stream) {
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/webm; codecs=opus',
        })

        record.onclick = function () {
          mediaRecorder.start()
          console.log(mediaRecorder.state)
          console.log('recorder started')
          record.style.background = 'red'

          stop.disabled = false
          record.disabled = true

          visualize(stream)
        }

        stop.onclick = function () {
          cancelAnimationFrame(animationFrameID.current)
          canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
          mediaRecorder.stop()
          console.log(mediaRecorder.state)
          console.log('recorder stopped')
          record.style.background = ''
          record.style.color = ''
          // mediaRecorder.requestData();

          stop.disabled = true
          record.disabled = false
        }

        mediaRecorder.onstop = function () {
          console.log('data available after MediaRecorder.stop() called.')

          const clipContainer = document.createElement('article')
          const clipLabel = document.createElement('p')
          const audio = document.createElement('audio')
          const deleteButton = document.createElement('button')

          clipContainer.classList.add('clip')
          audio.setAttribute('controls', '')
          deleteButton.textContent = 'Cancella audio'
          deleteButton.className = 'delete small-button'

          clipLabel.textContent = 'audio.webm'

          clipContainer.innerHTML = null
          soundClips.innerHTML = null
          clipContainer.appendChild(audio)
          clipContainer.appendChild(deleteButton)
          soundClips.appendChild(clipContainer)

          audio.controls = true
          const blob = new Blob(chunks, { type: 'audio/webm; codecs=opus' })
          const file = new File([blob], 'audio.webm', {
            type: 'audio/webm; codecs=opus',
          })
          chunks = []
          const audioURL = window.URL.createObjectURL(blob)
          audio.src = audioURL
          console.log('recorder stopped')

          setRec(file)

          deleteButton.onclick = function (e) {
            e.target.closest('.clip').remove()
          }
        }

        mediaRecorder.ondataavailable = function (e) {
          chunks.push(e.data)
        }
      }

      const onError = function (err) {
        console.log('The following error occured: ' + err)
      }

      navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError)
    } else {
      console.log('getUserMedia not supported on your browser!')
    }

    const visualize = (stream) => {
      if (!audioCtx) {
        audioCtx = new AudioContext()
      }

      const source = audioCtx.createMediaStreamSource(stream)

      const analyser = audioCtx.createAnalyser()
      analyser.fftSize = 2048
      const bufferLength = analyser.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength)

      source.connect(analyser)
      //analyser.connect(audioCtx.destination);

      draw()

      function draw() {
        const WIDTH = canvas.width
        const HEIGHT = canvas.height

        animationFrameID.current = requestAnimationFrame(draw)

        analyser.getByteTimeDomainData(dataArray)

        canvasCtx.fillStyle = 'rgb(200, 200, 200)'
        canvasCtx.fillRect(0, 0, WIDTH, HEIGHT)

        canvasCtx.lineWidth = 2
        canvasCtx.strokeStyle = 'rgb(0, 0, 0)'

        canvasCtx.beginPath()

        const sliceWidth = (WIDTH * 1.0) / bufferLength
        let x = 0

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0
          const y = (v * HEIGHT) / 2

          if (i === 0) {
            canvasCtx.moveTo(x, y)
          } else {
            canvasCtx.lineTo(x, y)
          }

          x += sliceWidth
        }

        canvasCtx.lineTo(canvas.width, canvas.height / 2)
        canvasCtx.stroke()
      }
    }

    window.onresize = function () {
      canvas.width = mainSection.offsetWidth
    }

    window.onresize()
  }, [])
}
