import React from 'react'
import { AnalyzeText } from '../analyzeText/AnalyzeText'
import { Record } from '../record/Record'
import { UploadFile } from '../uploadFile/uploadFile'
import './Tabs.css'

export const TabsComponent = () => {
  const [tabsValue, setTabsValue] = React.useState('upload-file')

  const buttons = [
    {
      label: 'Carica file',
      value: 'upload-file',
      component: <UploadFile />,
    },
    {
      label: 'Registra audio',
      value: 'record-audio',
      component: <Record />,
    },
    {
      label: 'Inserisci testo',
      value: 'write-text',
      component: <AnalyzeText />,
    },
  ]

  const $Component = buttons.find((b) => b.value === tabsValue).component

  return (
    <div className="tabs-container">
      <div className="tabs-buttons-container">
        {buttons.map((button, i) => (
          <button
            key={i}
            className={button.value === tabsValue ? 'selected' : ''}
            onClick={() => setTabsValue(button.value)}
          >
            {button.label}
          </button>
        ))}
      </div>
      <div className="tabs-component">{$Component}</div>
    </div>
  )
}
