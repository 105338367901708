import axios from 'axios'
import { useLogout } from './useAuth'
import { useApi } from './useApi'
import toast from 'react-hot-toast'
import React from 'react'
import { EToastIDs } from '../utils/types'
import { useSetRecoilState } from 'recoil'
import { loaderState } from '../recoil'

export const useInterceptor = () => {
  const logout = useLogout()
  const { refreshToken } = useApi()
  const setLoader = useSetRecoilState(loaderState)

  React.useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('access_token')
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token
        }
        return config
      },
      (error) => {
        Promise.reject(error)
      },
    )

    axios.interceptors.response.use(
      (response) => {
        return response
      },
      function (error) {
        const originalRequest = error.config
        if (
          error.response?.status === 401 &&
          (/\/auth\/refresh/.test(originalRequest.url) ||
            /\/auth\/checkTokenValidity/.test(originalRequest.url))
        ) {
          logout()
          toast.error('La sessione è scaduta, effettua nuovamente il login.', {
            id: EToastIDs.ExpiredSession,
          })
          return Promise.reject(error)
        }

        const refresh_token = localStorage.getItem('refresh_token')
        if (
          error.response?.status === 401 &&
          !originalRequest._retry &&
          refresh_token
        ) {
          originalRequest._retry = true
          return refreshToken({
            refresh_token,
          })
            .then((res) => {
              localStorage.setItem('access_token', res.access_token)
              localStorage.setItem('refresh_token', res.refresh_token)
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + res.access_token
              return axios(originalRequest)
            })
            .catch((error) => console.log(error))
        }
        return Promise.reject(error)
      },
    )
  }, [])
}
