import React from 'react'
import './Logout.css'
import { useLogout } from '../../hooks/useAuth'

export const Logout = () => {
  const logout = useLogout()
  return (
    <button id="logout" onClick={logout}>
      Logout
    </button>
  )
}
