export interface ITranscribeAudioFileResponse {
  transcription: string
}

export interface IAnalyzeSentimentResponse {
  sentiment: string
}

export interface ILoginResponse {
  id: string
  username: string
  access_token: string
  refresh_token: string
}

export interface IRefreshTokenPayload {
  refresh_token: string
}

export interface IRefreshTokenResponse {
  access_token: string
  refresh_token: string
}

export interface IError {
  error: string
  message: string
  link?: string
  statusCode: number
}

export enum EChannels {
  Left = 'left',
  Right = 'right',
  Both = 'both',
}

export enum EPages {
  Home = 'Home',
  Login = 'Login',
}

export enum EToastIDs {
  UpdatedDossiers = 'updated-dossiers',
  ExpiredSession = 'expired-session',
}
