import React from 'react'
import { useAnalyseSentiment } from '../../hooks/useAnalyseSentiment'
import './AnalyzeText.css'

export const AnalyzeText = () => {
  const [text, setText] = React.useState<string>(null)
  const { analyseFromText } = useAnalyseSentiment()

  return (
    <div className="analyze-text-container">
      <textarea
        id="analyze-text-input"
        value={text}
        placeholder="...inserisci il testo..."
        onChange={(event) => setText(event.target.value)}
      />
      <button
        className="home-button black-button"
        onClick={() => analyseFromText(text)}
        disabled={!text || !text.length}
      >
        Analizza testo
      </button>
    </div>
  )
}
