import React from 'react'
import './Error.css'
import { useRecoilState } from 'recoil'
import { errorState } from '../../recoil'

export const Error = () => {
  const [error, setError] = useRecoilState(errorState)
  return (
    <div id="error-container" onClick={() => setError(null)}>
      <div id="error">
        {error.message ? (
          <span>{error.message}</span>
        ) : (
          <>
            <span>{error.statusCode} - </span>
            <span dangerouslySetInnerHTML={{ __html: error.error }} />
          </>
        )}
        <button className="close">Chiudi</button>
      </div>
    </div>
  )
}
