import { atom } from 'recoil'
import {
  EPages,
  IAnalyzeSentimentResponse,
  ITranscribeAudioFileResponse,
} from '../utils/types'

export const authState = atom<{ access_token: string; refresh_token: string }>({
  key: 'AuthState',
  default: null,
})

export const pageState = atom<EPages>({
  key: 'PageState',
  default: null,
})

export const transcriptionState = atom<ITranscribeAudioFileResponse>({
  key: 'TranscriptionState',
  default: null,
})

export const sentimentState = atom<IAnalyzeSentimentResponse>({
  key: 'SentimentState',
  default: null,
})

export const loaderState = atom<boolean>({
  key: 'LoaderState',
  default: false,
})

export const errorState = atom<{
  message: string
  statusCode: number
  error: string
}>({
  key: 'ErrorState',
  default: null,
})
