import React from 'react'
import './Loader.css'

export const Loader = () => {
  return (
    <div id="loader-container">
      <div id="loader" />
    </div>
  )
}

export const InlineLoader = () => {
  return <div id="loader" />
}
