import { useSetRecoilState } from 'recoil'
import { loaderState } from '../recoil'
import {
  EChannels,
  IAnalyzeSentimentResponse,
  IError,
  ILoginResponse,
  IRefreshTokenPayload,
  IRefreshTokenResponse,
  ITranscribeAudioFileResponse,
} from '../utils/types'
import axios from 'axios'

const BASE_URL =
  window.location.hostname === 'localhost' ? 'http://localhost:3000' : ''

export const useApi = () => {
  const setLoader = useSetRecoilState(loaderState)

  const axiosPost = async (url, body, options?: { noLoader: boolean }) => {
    !options?.noLoader && setLoader(true)

    try {
      const res = await axios.post(url, body)
      !options?.noLoader && setLoader(false)
      return res?.data
    } catch (error: any) {
      !options?.noLoader && setLoader(false)
      throw error.response.data
    }
  }

  const login = (body: {
    username: string
    password: string
  }): Promise<ILoginResponse & IError> => {
    return axiosPost(`${BASE_URL}/auth/login`, body)
  }

  const refreshToken = (
    body: IRefreshTokenPayload,
  ): Promise<IRefreshTokenResponse & IError> => {
    return axiosPost(`${BASE_URL}/auth/refresh`, body, { noLoader: true })
  }

  const checkTokenValidity = (body: {
    refresh_token: string
  }): Promise<any> => {
    return axiosPost(`${BASE_URL}/auth/checkTokenValidity`, body)
  }

  const transcribeAudioFile = (
    body: FormData,
    channel: EChannels,
  ): Promise<ITranscribeAudioFileResponse & IError> => {
    return axiosPost(`${BASE_URL}/app/splitAndTranscribe/${channel}`, body)
  }

  const analyzeSentiment = (
    body: {
      transcription: string
      checkLanguage?: boolean
    },
    options?: { noLoader: boolean },
  ): Promise<IAnalyzeSentimentResponse & IError> => {
    return axiosPost(`${BASE_URL}/app/evaluate`, body, options)
  }

  return {
    login,
    refreshToken,
    transcribeAudioFile,
    analyzeSentiment,
    checkTokenValidity,
  }
}
