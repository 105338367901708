import React from 'react'
import { useRecoilValue } from 'recoil'
import './App.css'
import { Loader } from './components/loader/Loader'
import { authState, errorState, loaderState, pageState } from './recoil'
import { Home } from './screens/Home'
import { Error } from './components/error/Error'
import { Login } from './screens/Login/Login'
import { EPages } from './utils/types'
import { Logout } from './components/logout/Logout'
import { useAuth } from './hooks/useAuth'
import { useInterceptor } from './hooks/useInterceptor'
import { Toaster } from 'react-hot-toast'

const App = () => {
  const loader = useRecoilValue(loaderState)
  const error = useRecoilValue(errorState)
  const auth = useRecoilValue(authState)
  const page = useRecoilValue(pageState)

  useInterceptor()
  useAuth()

  const Components = {
    [EPages.Login]: Login,
    [EPages.Home]: Home,
  }

  const $Component = Components[page]

  return (
    <div className="App">
      <header id="header">
        <img id="logo" src="logo-responsa.svg" />
        {auth?.access_token && <Logout />}
      </header>
      {auth ? (
        <div id="content-wrapper">{$Component ? <$Component /> : null}</div>
      ) : null}
      {loader && <Loader />}
      {error && <Error />}
      <Toaster position="top-right" toastOptions={{ duration: 5000 }} />
    </div>
  )
}

export default App
